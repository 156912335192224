import React from 'react';
import Header from './Header';
import Services from './Services';
import Footer from './Footer';
import Faqs from './Faqs';
import Team from './Team';
import Newsletter from './Newsletter';
import How from './How';
// import {useTranslation} from 'react-i18next';

function Home() {
  // const {t} = useTranslation();
  return (
    <div >
        <Header />
        <How />
        <Services />
        <Team />
        <Newsletter />
        <Faqs />
        <Footer />
    </div>
  );
}

export default Home;
