import * as React from "react"
const Sweep = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={200}
    height={200}
    fill={"#fff"}
    viewBox="0 0 52.917 52.917"
    {...props}
  >
    <g transform="translate(269.367 -284.334)">
      <rect
        width={52.917}
        height={52.917}
        x={-269.367}
        y={284.334}
        rx={6.615}
        ry={6.615}
        style={{
          fill: "",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
          strokeWidth: 1.01825,
          strokeDasharray: "none",
          stopColor: "#000",
        }}
      />
      <rect
        width={52.917}
        height={52.917}
        x={-269.367}
        y={284.334}
        rx={6.615}
        ry={6.615}
        style={{
          fill: "",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
          strokeWidth: 1.01825,
          strokeDasharray: "none",
          stopColor: "#000",
        }}
      />
      <path
        d="M-246.249 326.365c-1.437-.633-1.782-1.277-1.483-2.769.142-.71.105-.8-.215-.535-.212.176-.54.69-.726 1.143-.188.452-.43.822-.537.822-.189 0-3.686-1.376-3.824-1.505-.037-.035.316-.772.785-1.638.996-1.838.97-2.66-.033-1.107-.378.584-1.123 1.292-1.656 1.572-.951.5-.99.5-2.314-.095l-1.347-.605.88-.536c.884-.537 1.078-.886 2.77-4.997l.874-2.12 5.043 2.065c2.774 1.136 5.143 2.165 5.265 2.286.121.121-.347 1.548-1.04 3.17-1.288 3.018-1.477 3.971-.973 4.914.359.67.178.662-1.469-.064zm8.824.165c-.24-.24-.436-.713-.436-1.053 0-.539-.12-.603-.943-.507-1.242.145-2.105-.68-2.105-2.012 0-1.146.872-2.09 1.933-2.09.532 0 .731-.15.764-.579.072-.957.827-1.846 1.855-2.185 1.75-.577 3.485.737 3.485 2.64 0 .373.25.928.554 1.233.825.825.74 2.309-.176 3.029-.401.316-1.01.574-1.354.574-.359 0-.816.294-1.077.693-.53.81-1.816.941-2.5.257zm7.103-7.657c-.828-.827.09-2.123 1.115-1.574.817.437.449 1.907-.478 1.907-.168 0-.454-.15-.637-.333zm-17.303-3.629c-2.702-1.098-4.875-2.122-4.829-2.274.047-.153.5-.558 1.006-.9 1.307-.887 2.895-.814 5.426.249 2.59 1.087 3.727 2.26 3.727 3.848 0 .604-.094 1.093-.208 1.086-.114-.006-2.42-.91-5.122-2.009zm13.212.886c-.293-.473.631-1.27 1.021-.88.174.173.241.511.15.75-.192.5-.894.578-1.17.13zm4.619-1.74c-.46-.552-.074-1.282.677-1.282.78 0 1.052.37.817 1.112-.201.634-1.03.73-1.494.17zm-16.035-3.018c-.343-.158-.623-.38-.623-.49 0-.49 6.471-15.948 6.753-16.13.173-.112.43-.167.571-.122.916.29.688 1.102-2.47 8.787-1.784 4.34-3.325 7.97-3.425 8.067-.1.097-.463.047-.806-.112z"
        style={{
          fill: "#fff",
          strokeWidth: 1.04749,
        }}
      />
    </g>
  </svg>
)
export default Sweep
