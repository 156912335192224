import * as React from "react"
const Vacuum = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={200}
    height={200}
    fill={"#fff"}
    viewBox="0 0 52.917 52.917"
    {...props}
  >
    <g transform="translate(179.946 -389.178)">
      <rect
        width={52.917}
        height={52.917}
        x={-179.946}
        y={389.178}
        rx={6.615}
        ry={6.615}
        style={{
          fill: "",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
          strokeWidth: 1.01825,
          strokeDasharray: "none",
          stopColor: "#000",
        }}
      />
      <path
        d="M-167.776 422.728c0-7.813.204-8.894 2.06-10.96.654-.726 1.632-1.547 2.174-1.824.727-.37 1.03-.721 1.153-1.332.121-.607.381-.91.973-1.133.635-.24.867-.54 1.084-1.401.36-1.43 1.638-2.957 2.989-3.57.89-.404 1.757-.49 4.988-.495 5.108-.008 6.306.465 7.69 3.037.542 1.007.574 1.455.653 9.194l.083 8.136h-1.328l-.08-8.004c-.076-7.445-.118-8.066-.61-8.902-1.109-1.882-1.52-2.012-6.35-2.012-3.986 0-4.386.044-5.074.557-.757.565-1.494 1.654-1.769 2.618-.117.409.043.62.7.926.609.284.875.605.935 1.129.062.537.313.827.945 1.091 1.429.597 2.847 2.05 3.642 3.731l.752 1.59V429.26h-3.174v-2.129l-4.564.072-4.564.073-.082.992-.082.992h-3.144zm2.117 2.68c0-2.045-.072-2.499-.397-2.499-.324 0-.397.451-.397 2.47 0 1.358.076 2.545.17 2.638.442.443.624-.319.624-2.61zm12.38-.01c-.061-1.884-.156-2.357-.474-2.357-.52 0-.725 4.207-.234 4.8.574.691.787-.042.708-2.443zm-3.45.09c-.077-.233-1.017-.352-3.197-.405-2.629-.063-3.087-.014-3.087.33 0 .343.502.406 3.197.406 2.44 0 3.171-.078 3.087-.33zm.066-2.182c0-.331-.528-.397-3.16-.397-2.883 0-3.733.162-3.271.624.093.093 1.578.17 3.3.17 2.607 0 3.13-.067 3.13-.397zm-.952-3.556c2.754-1.702 2.435-5.888-.55-7.208-2.004-.886-3.847-.378-5.2 1.434-.71.951-.884 3.06-.34 4.113.458.885 1.815 2.031 2.676 2.261.937.25 2.474-.02 3.414-.6zm-4.204-.854c-1.697-1.335-1.801-3.602-.23-4.981.88-.773 1.367-.915 2.74-.798 2.093.178 3.297 2.739 2.245 4.773-.81 1.566-3.358 2.105-4.755 1.006zm2.823-2.734c.588-1.29-.653-2.597-1.583-1.667-.175.175-.317.627-.317 1.005 0 1.354 1.367 1.83 1.9.662zm8.947 12.7c0-.34.78-.397 5.424-.397 4.645 0 5.424.057 5.424.397s-.779.397-5.424.397c-4.644 0-5.424-.057-5.424-.397zm.53-2.058c0-1.032.678-1.693 1.906-1.858.782-.105 1.301-.363 1.674-.83.714-.895 1.568-.858 2.678.116.559.49 1.196.793 1.67.793.979 0 1.597.622 1.597 1.607v.775H-149.52z"
        style={{
          fill: "#fff",
          strokeWidth: 1,
        }}
      />
    </g>
  </svg>
)
export default Vacuum
