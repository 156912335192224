import React from "react";

import service1 from "./img/service-1-1.jpg";
import service2 from "./img/service-2-1.jpg";
import service3 from "./img/service-3-1.jpg";
import service4 from "./img/service-4-1.jpg";

import Livingroom from "./icons/Livingroom";
import Bedroom from "./icons/Bedroom";
import Kitchen from "./icons/Kitchen";
import Bathroom from "./icons/Bathroom";
import {useTranslation} from 'react-i18next';

function Services() {
  const {t} = useTranslation();
  return (
    <section id="services">
      <div className="service">
        <div className="container">
          <div className="section-header">
            <p>{t("the-services")}</p>
            <h2>{t("services-title")}</h2>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="service-item">
                <img src={service1} alt="Service" />
                <h3>{t("bedroom")}</h3>
                <p>
                  {t("bedroom-services")}
                </p>
                <Bedroom width="40px" height="40px" className="icon" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="service-item">
                <img src={service2} alt="Service" />
                <h3>{t("livingroom")}</h3>
                <p>
                  {t("livingroom-services")}
                </p>
                {/* <a className="btn" href="">Learn More</a> */}
                <Livingroom width="40px" height="40px" className="icon" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="service-item">
                <img src={service3} alt="Service" />
                <h3>{t("kitchen")}</h3>
                <p>
                  {t("kitchen-services")}
                </p>
                {/* <a className="btn" href="">Learn More</a> */}
                <Kitchen width="40px" height="40px" className="icon" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="service-item">
                <img src={service4} alt="Service" />
                <h3>{t("bathroom")}</h3>
                <p>
                  {t("bathroom-services")}
                </p>
                {/* <a className="btn" href="">Learn More</a> */}
                <Bathroom width="40px" height="40px" className="icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
