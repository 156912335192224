import './App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './Home';
import reportWebVitals from './reportWebVitals';


function App() {
  return (
    <div className="App">
      <Home/>

    </div>
  );
}

export default App;