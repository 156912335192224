import React from 'react';
import Diagrams from './gifs/diagram.jpg'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';

function How() {
    const {t} = useTranslation();

    const titles=[t("if-home-owner"), t("if-home-owner"),t("if-cleaner"),t("if-cleaner")]
    const text=[t("request-cleaner"), t("send-you-one"), t("register-your-acc"), t("send-you-to-a-home")]
    const text1=[t("request-cleaner"), t("request-cleaner"), t("register-your-acc"), t("register-your-acc")]
    const text2=["", t("send-you"),"",t("program-you")]
    const text3=["", t("clean-home"),"",t("to-clean")]
    const numbers1=["1","1","1","1"]
    const numbers2=["2","2","2","2"]
    const [index, setIndex] = useState(0);

    
 
    useEffect(() => {
        const intervalDelayMilliseconds = text[index].length * 120;
        const interval = setInterval(() => {
        setIndex((prevIndex) => {
            return prevIndex + 1 < text.length ? prevIndex + 1 : 0;
        });
        }, intervalDelayMilliseconds);

        return () => clearInterval(interval);
    },[]);
    return (
        <section>
            <div className="single">
                <div className="container">
                    <div className="section-header">
                        <p>{t("how-about")}</p>
                        <h2>{t("how-title")}</h2>
                    </div>
                    <div className="row">
                        <div className="container-a">
                                <img className="img-fluid rounded theimg" src={Diagrams} alt="Image"/>
                                <div className="container-b">
                                    <div className="thetitle">{ titles[index] }</div>
                                    <div className="thetext-a"><span>{numbers1[index]}</span>{ text1[index] }</div>
                                    
                                    {index % 2 !== 0 ? <div className="thetext-b"><span >{numbers2[index]}</span>{ text2[index]}</div> : null}
                                    
                                    {index % 2 !== 0 ? <div className="thetext-c">{ text3[index]}</div> : null}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>
);
}

export default How;




