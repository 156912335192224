import React from 'react';
import Axios from "axios";
import { useState } from "react";
import {Loader} from './components/Loader'
import {useTranslation} from 'react-i18next';

function Footer() {
    const [emailPhone, setEmailPhone] = useState("");
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation();

    const validateEmailandPhone = (emailandphone)=> {
        var isValidPhone =  emailandphone.match(/^[0-9]+$/) !== null &&
          emailandphone.startsWith("9") &&
          emailandphone.length === 9
        var isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailandphone)
        // if ( isValidEmail || isValidPhone ) {
        if ( isValidEmail ) {
          return true;
        }
        alert(t("wrong-email-message"));
        setLoading(false);
        return false;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (emailPhone === '') {
            alert(t("missing-fields"))
            setLoading(false);
        }
        else {
            if (validateEmailandPhone(emailPhone)){
              Axios.post(`${process.env.REACT_APP_SERVER_API}/add`, {
                userEmailPhone: emailPhone
              })
                .then((response) => {
                  if(response.data.success){
                      setEmailPhone("");
                      alert(t("form-success"))
                      setLoading(false);  
                    } else{
                      alert(t("form-data-exist"))
                      setLoading(false);
                      setEmailPhone("");
                  }
                })
                .catch((error) => {
                  //console.log(error.response.data.error);
                  setLoading(false);
                });
            }
        }
    }


    return (

        <div className="footer">
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-lg-3">
                    <div className="footer-contact">
                        <h2>{t("contact-us")}</h2>
                        <p><i className="fa fa-map-marker-alt"></i>1025 Prospect St #210, CA, USA</p>
                        <p><i className="fa fa-phone-alt"></i>+1 (949) 515-6955</p>
                        <p><i className="fa fa-envelope"></i>contact@kleenin.com</p>
                        <div className="footer-social">
                            <a ><i className="fab fa-twitter"></i></a>
                            <a ><i className="fab fa-facebook-f"></i></a>
                            <a ><i className="fab fa-youtube"></i></a>
                            <a ><i className="fab fa-instagram"></i></a>
                            <a ><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="footer-link">
                        <h2>{t("company")}</h2>
                        <a href="#">{t("company-about-us")}</a>
                        <a >{t("company-history")}</a>
                        <a >{t("company-services")}</a>
                        <a >{t("company-mission")}</a>
                        <a >{t("company-vision")}</a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="footer-link">
                        <h2>{t("support")}</h2>
                        <a >{t("support-clients")}</a>
                        <a >{t("support-reviews")}</a>
                        <a >{t("support-soon")}</a>
                        <a >{t("support-client")}</a>
                        <a >{t("support-faq")}</a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="footer-form">
                        <h2>{t("newsletter")}</h2>
                        <p>
                            {t("newsletter-description")}
                        </p>
                        <form onSubmit={handleSubmit}>
                            <input className="form-control" type="text"  placeholder={t("form-first-placeholder")} name="emailPhone" onChange={(e) => { setEmailPhone(e.target.value) }} value={emailPhone}/>
                            <button className="btn" disabled={loading} style={{width:"74px"}}>{!loading ? t("hero-form-button") : <Loader className="spinner" />}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div className="container footer-menu">
            <div className="f-menu">
                <a >{t("footer-terms-conditions")}</a>
                <a >{t("footer-privacy-policy")}</a>
                <a >{t("footer-cookies")}</a>
                <a >{t("footer-help")}</a>
                <a >{t("footer-contact")}</a>
            </div>
        </div>
        <div className="container copyright">
            <div className="row">
                <div className="col-md-6">
                    <p>&copy; <a >Kleenin</a>, All Right Reserved.</p>
                </div>
                <div className="col-md-6">
                    <p>Designed By <a >Kleenin</a></p>
                </div>
            </div>
        </div>
    </div>

    );
    }

export default Footer;


