import * as React from "react"
const Soap = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={200}
    height={200}
    fill={"#fff"}
    viewBox="0 0 52.917 52.917"
    {...props}
  >
    <g transform="translate(-15.446 -316.575)">
      <rect
        width={52.917}
        height={52.917}
        x={15.446}
        y={316.575}
        rx={6.615}
        ry={6.615}
        style={{
          fill: "",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
          strokeWidth: 1.01825,
          strokeDasharray: "none",
          stopColor: "#000",
        }}
      />
      <path
        d="M30.771 356.922c-.216-.089-.33-.724-.33-1.834v-1.697h13.782l-.078 1.786-.079 1.785-6.482.048c-3.565.026-6.631-.013-6.813-.088zm-.33-7.897v-3.043h13.758v6.086H30.44zm5.523 1.216c.724-.458-.02-.82-1.687-.82-1.632 0-2.194.242-1.852.795.2.324 3.035.345 3.54.025zm1.548-2.057c.074-.222-.636-.337-2.452-.397-2.492-.083-2.976.025-2.63.586.242.391 4.948.216 5.082-.19zm-6.873-4.182c.255-1.331 1.76-3.409 3.045-4.201 1.78-1.098 4.517-1.28 6.488-.433 1.998.86 3.383 2.5 3.92 4.642l.164.65H30.514zm18.715-5.97c-.244-.77.412-1.426 1.18-1.182.433.137.554.356.493.887-.11.949-1.394 1.174-1.673.294zm-14.364-1.01c.08-.686.115-.698 2.396-.773l2.315-.076v1.545h-4.792zm10.171-.432c-.317-.828.446-1.609 1.266-1.294.538.206.743.902.39 1.328-.403.484-1.465.463-1.656-.034zm5.388-1.19c-1.22-1.22-.243-2.808 1.647-2.678.638.044 1.345 1.208 1.135 1.87-.43 1.352-1.83 1.76-2.782.807zm-13.611-.47c-.081-.082-.148-.604-.148-1.162 0-.906.071-1.014.662-1.014.592 0 .661.107.661 1.027 0 .795-.116 1.058-.514 1.162-.282.074-.58.068-.661-.013zm10.442-2.964c-.664-.664-.668-.863-.025-1.444.612-.554 1.506-.353 1.742.39.193.607.019.946-.674 1.317-.397.213-.625.155-1.043-.263zm-3.494-.602c-.073-.515-.28-.613-1.54-.727-.862-.079-1.482-.267-1.52-.463-.049-.24.446-.33 1.79-.33 1.713 0 1.882.05 2.152.644.162.354.223.83.135 1.058-.238.62-.92.498-1.017-.182zm-8.948-1.124v-1.19h4.763v2.38H34.939z"
        style={{
          fill: "#fff",
          strokeWidth: 1,
        }}
      />
    </g>
  </svg>
)
export default Soap
