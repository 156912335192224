import React from 'react';
import './App.css';
import EmailForm from './EmailForm'
import {useTranslation} from 'react-i18next';

function Header() {
    const {t} = useTranslation();
    const { linedes1, linedes2 } = t("hero-description")
    const { linesub1, linesub2 } = t("hero-sub")
    const { lineform1, lineform2 } = t("hero-form")

    return (
        <div className="header home">
        <div className="container-fluid" style={{width: "80%"}} >
            
            <div className="header row align-items-center  fixed-top  bg-transparent" >
                <div className="col-lg-3">
                    <div className="brand">
                        <a href="index.html">
                            Kleenin
                        </a>
                    </div>
                </div>
                <div className="col-lg-9" >
                    <div className="navbar navbar-expand-lg bg-light navbar-light">
                    <a href="#" className="navbar-brand">{t("menu")}</a>
                                    <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                        <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <div className="navbar-nav ml-auto">
                                <a href="index.html" className="nav-item nav-link active">{t("home")}</a>
                                <a href="#services" className="nav-item nav-link">{t("services")}</a>
                                <a href="#newsletter" style={{width: "122.19px"}} className="btn">{t("nav-button")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hero row align-items-center">
                    <div className="col-md-7">
                        <h2>{linedes1}</h2>
                        <h2>{linedes2}</h2>
                        <p><span style={{fontWeight: "bold"}}>{linesub1}</span> {linesub2}</p>
                        <a className="btn" style={{width: "161.14px"}} href="#newsletter">{t("hero-button")}</a>
                    </div>
                    <div className="col-md-5">
                        <div className="form">
                            <h3>{lineform1}</h3>
                            <h3>{lineform2}</h3>
                            <EmailForm />
                        </div>
                    </div>
    
            </div>
        </div>
    </div>
      

  );
}

export default Header;
