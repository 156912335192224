import * as React from "react"
const Kitchen = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={186.004}
    height={186.004}
    viewBox="0 0 49.214 49.214"
    {...props}
  >
    <g transform="translate(353.994 -1099.996)">
      <rect
        width={49.214}
        height={49.214}
        x={-353.994}
        y={1099.996}
        rx={10.111}
        ry={10.111}
        style={{
          fill: "#ffd662",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
          strokeWidth: 0.0289998,
          stopColor: "#000",
        }}
      />
      <path
        d="M-273.648 865.385v-9.94l.728.085.727.083.132 9.128.133 9.128 23.213.062 23.214.062.07-9.19.07-9.19.727-.083.728-.084V875.325h-49.742zm17.198.282v-5.953h15.346v11.906h-15.346zm13.698-.066-.072-4.431h-11.906l-.073 4.431-.072 4.432h12.196zm-10.475-1.805.084-.68 4.366-.016 4.365-.014.084.695.084.696h-9.066zm-11.624 1.8-4.828-.073v-1.311h11.112v1.263l-.727.096c-.4.053-2.901.064-5.557.024zm25.863-.722v-.662h10.848v.243c0 .134.073.432.16.662l.161.418h-11.169zm-36.662-8.683c-.082-.212-.11-.904-.065-1.54l.082-1.154 26.922-.068 26.921-.067v3.213l-.727-.084-.728-.084-.084-.727-.083-.728h-50.73l-.084.728-.084.727-.596.085-.597.085zm20.17-4.326c-.097-.097-.176-.455-.176-.794v-.618h2.469c2.756 0 2.797.015 2.621.933l-.125.655h-2.306c-1.269 0-2.386-.08-2.483-.176zm8.74.01c-.102-.103-.185-.464-.185-.804v-.617H-241.863l-.084.728-.083.727-2.262.076c-1.244.041-2.345-.008-2.447-.11zm-28.055-9.535c-.097-.097-.176-2.716-.176-5.82v-5.645h52.387v11.641H-248.6c-14.31 0-26.097-.079-26.194-.176zm14.111-5.644v-4.234h-12.7V840.93h12.7zm4.498 2.397v-1.836l.954-.082.953-.083 1.278-2.315 1.277-2.315h-7.372V840.93h2.91zm13.494.778v-1.059h-11.906v2.117h11.906zm4.498-3.175v-4.234h-7.452l.088.203c.048.112.614 1.177 1.256 2.368l1.169 2.165.948.08.948.079.079 1.786.078 1.786h2.886zm14.288 0v-4.234h-12.965V840.93h12.965zm-22.217-1.786-1.277-2.315-1.314-.08-1.314-.078-1.16 2.196a143.08 143.08 0 0 0-1.25 2.393l-.088.199h7.681z"
        style={{
          fill: "#fff",
          stroke: "#fff",
          strokeWidth: 1,
          strokeDasharray: "none",
        }}
        transform="translate(-196.172 667.758) scale(.53551)"
      />
    </g>
  </svg>
)
export default Kitchen
