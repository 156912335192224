import React from "react";
import Axios from "axios";
import { useState } from "react";
import {useTranslation} from 'react-i18next';
import {Loader} from './components/Loader'

function Newsletter() {
    const [emailPhone, setEmailPhone] = useState("");
    const [role, setRole] = useState("");
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation();

    const validateEmailandPhone = (emailandphone)=> {
        var isValidPhone =  emailandphone.match(/^[0-9]+$/) !== null &&
            emailandphone.startsWith("9") &&
            emailandphone.length === 9
        var isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailandphone)
        // if ( isValidEmail || isValidPhone ) {
        if ( isValidEmail ) {
            return true;
        }
        alert(t("wrong-email-message"));
        //alert("Por favor, ingrese un número o email válido!")
        setLoading(false);
        return false;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (emailPhone === '' || role === '' ) {
            alert(t("missing-fields"))
            setLoading(false);
        }
        else {
            if (validateEmailandPhone(emailPhone)){
            Axios.post(`${process.env.REACT_APP_SERVER_API}/insert`, {
                        userEmailPhone: emailPhone,
                        userRole: role,
                })
                .then((response) => {
                    if(response.data.success){
                        setEmailPhone("");
                        setRole("");
                        alert(t("form-success"))
                        setLoading(false);
                    } else{
                        alert(t("form-data-exist"))
                        setEmailPhone("");
                        setRole("");
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    //console.log(error.response.data.error);
                });
            }
        }
    }

    return (
        <section id="newsletter">
        <div className="newsletter">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-md-8">
                <h2>{t("soon")}</h2>
                <p><b>
                  {t("launch")}
                  </b>
                </p>
                <p>
                  {t("subscribe")}
                </p>
                <p>
                  {t("discount")}
                </p>
                </div>
                <div className="col-md-4">
                <div className="form">
                <form onSubmit={handleSubmit}>
                    <input className="form-control" type="text" placeholder={t("form-first-placeholder")} name="emailPhone" onChange={(e) => { setEmailPhone(e.target.value) }} value={emailPhone} />
                    <div className="control-group">
                    <select className="custom-select" value={role} onChange={(e) => {setRole(e.target.value)}} >
                            <option value="" hidden>{t("form-second-placeholder")}</option>
                            <option value="limpiador">{t("form-first-option")}</option>
                            <option value="propietario">{t("form-second-option")}</option>
                        </select>
                    </div>
                    <button className="btn" type="submit" disabled={loading} style={{width:"124px"}}>{!loading ? t("hero-form-button") : <Loader className="spinner" />}</button>
                    </form>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
  );
}

export default Newsletter;
