import team1a from "./img/team-1a.jpg"
import team2a from "./img/team-2a.jpg"
import team3a from "./img/team-3a.jpg"
import team4a from "./img/team-4a.jpg"


import Sweep from "./icons/Sweep"
import Mop from "./icons/Mop"
import Soap from "./icons/Soap"
import Wipedust from "./icons/Wipedust"
import Wetwipes from "./icons/Wetwipes"
import Vacuum from "./icons/Vacuum"
import Disinfect from "./icons/Disinfect"
import {useTranslation} from 'react-i18next';

function Team() {
    const {t} = useTranslation();
    return (
        <div className="team">
        <div className="container">
            <div className="section-header">
                <p>{t("experts")}</p>
                <h2>{t("our-cleaners")}</h2>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="team-item">
                        <div className="team-img">
                            <img src={team1a} alt="Team Image"/>
                        </div>
                        <a target="_blank" href="https://homecleanheroes.com/one-time-house-cleaning-services/">
                            <div className="team-text">
                                <h2>One-Time Cleaning</h2>
                                <h3>{t("cleaner-agent")}</h3>
                                <div className="team-social">
                                    <div className="icona" > <Sweep height="40px" width="40px" fill="#00acee"/> </div>
                                    <div className="icona"><Soap height="40px" width="40px" fill="#3b5998"/> </div>
                                    <div className="icona"><Vacuum  height="40px" width="40px" fill="#0e76a8" /> </div>
                                    <div className="icona"><Wipedust   height="40px" width="40px"fill="#3f729b"/> </div>

                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="team-item">
                        <div className="team-img">
                            <img src={team2a} alt="Team Image"/>
                        </div>
                        <a target="_blank" href="https://www.tessacleaning.com/">
                            <div className="team-text">
                                <h2>Tessa Cleaning</h2>
                                <h3>{t("cleaner-agent")}</h3>
                                <div className="team-social">
                                    <div className="icona" > <Mop height="40px" width="40px" fill="#00acee"/> </div>
                                    <div className="icona"><Wipedust height="40px" width="40px" fill="#3b5998"/> </div>
                                    <div className="icona"><Disinfect  height="40px" width="40px" fill="#0e76a8" /> </div>
                                    <div className="icona"><Wetwipes   height="40px" width="40px"fill="#3f729b"/> </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="team-item">
                        <div className="team-img">
                            <img src={team3a} alt="Team Image"/>
                        </div>
                        <a target="_blank" href="https://busybeecleaningcompany.com/">
                            <div className="team-text">
                                <h2>Busy Bee Cleaning</h2>
                                <h3>{t("cleaner-agent")}</h3>
                                <div className="team-social">
                                    <div className="icona" ><Vacuum height="40px" width="40px" fill="#00acee"/> </div>
                                    <div className="icona"><Mop height="40px" width="40px" fill="#3b5998"/> </div>
                                    <div className="icona"><Sweep  height="40px" width="40px" fill="#0e76a8" /> </div>
                                    <div className="icona"><Wipedust   height="40px" width="40px"fill="#3f729b"/> </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="team-item">
                        <div className="team-img">
                            <img src={team4a} alt="Team Image"/>
                        </div>
                        <a target="_blank" href="https://www.mollymaid.com/">
                            <div className="team-text">
                                <h2>Molly Maid</h2>
                                <h3>{t("cleaner-agent")}</h3>
                                <div className="team-social">
                                    <div className="icona" ><Disinfect height="40px" width="40px" fill="#00acee"/> </div>
                                    <div className="icona"><Wipedust height="40px" width="40px" fill="#3b5998"/> </div>
                                    <div className="icona"><Vacuum  height="40px" width="40px" fill="#0e76a8" /> </div>
                                    <div className="icona"><Wetwipes   height="40px" width="40px"fill="#3f729b"/> </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
          
  
    );
  }
  
  export default Team;
  

