import * as React from "react"
const Wetwipes = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={200}
    height={200}
    fill={"#fff"}
    viewBox="0 0 52.917 52.917"
    {...props}
  >
    <g transform="translate(60.08 -334.604)">
      <rect
        width={52.917}
        height={52.917}
        x={-60.08}
        y={334.604}
        rx={6.615}
        ry={6.615}
        style={{
          fill: "",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
          strokeWidth: 1.01825,
          strokeDasharray: "none",
          stopColor: "#000",
        }}
      />
      <path
        d="M-42.56 376.051c-.213-.991-.372-1.19-.95-1.19h-.695v-15.02h.705c.669 0 1.35-.813 1.35-1.61 0-.203.966-.367 2.155-.367h2.155l.503 1.482c.277.815.564 2.136.64 2.935.129 1.375.105 1.459-.45 1.58a.723.723 0 0 0-.584.723c0 .503.605.609 3.992.7 3.988.107 4.648-.082 4.167-1.196-.16-.371-.171-1.29-.036-3.08.105-1.403.193-2.685.195-2.848.007-.434 4.32-.363 4.32.07 0 .797.682 1.61 1.35 1.61h.705v15.02h-.708c-.57 0-.76.213-.979 1.088l-.27 1.087-8.654.103-8.654.103zm17.416-1.091c.106-.598.154-1.31.105-1.581-.117-.654-17.011-.728-17.221-.075-.074.23-.022.942.116 1.58l.25 1.163h16.555zm-10.845-12.253c-.006-1.474-.628-4.005-1.4-5.695-1.242-2.722-1.252-4.429-.036-6.397.78-1.26.915-1.283 1.59-.261 1.366 2.067 3.61-.374 4.073-4.431.182-1.6.428-1.36.789.775.665 3.928.972 13.34.517 15.85l-.226 1.246h-5.303l-.004-1.087z"
        style={{
          fill: "#fff",
          strokeWidth: 1.0772,
        }}
      />
    </g>
  </svg>
)
export default Wetwipes
