import React from 'react';
import faqs from './img/faqs.jpg'
import {useTranslation} from 'react-i18next';

function Faqs() {
    const {t} = useTranslation();
  return (

    <div className="faqs">
    <div className="container">
        <div className="section-header">
            <p>{t("faqs-info")}</p>
        </div>
        <div className="row">
            <div className="col-md-5">
                <div className="section-header left">
                    <p>{t("faqs-sub")}</p>
                    <h2>{t("faqs-title")}</h2>
                </div>
                <img src={faqs} alt="Image"/>
            </div>
            <div className="col-md-7">
                <div id="accordion">
                    <div className="card">
                        <div className="card-header">
                            <a className="card-link collapsed" data-toggle="collapse" href="#collapseOne" aria-expanded="true">
                                <span>1</span>{t("faqs-first")}
                            </a>
                        </div>
                        <div id="collapseOne" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                            {t("faqs-first-answer")}
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <a className="card-link" data-toggle="collapse" href="#collapseTwo">
                                <span>2</span>{t("faqs-second")}
                            </a>
                        </div>
                        <div id="collapseTwo" className="collapse" data-parent="#accordion">
                            <div className="card-body">
                            {t("faqs-second-answer")}
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <a className="card-link" data-toggle="collapse" href="#collapseThree">
                                <span>3</span>{t("faqs-third")}
                            </a>
                        </div>
                        <div id="collapseThree" className="collapse" data-parent="#accordion">
                            <div className="card-body">
                            {t("faqs-third-answer")}
                            </div>
                        </div>
                    </div>
                    {/* <div className="card">
                        <div className="card-header">
                            <a className="card-link" data-toggle="collapse" href="#collapseFour">
                                <span>4</span> {t("faqs-fourth")}
                            </a>
                        </div>
                        <div id="collapseFour" className="collapse" data-parent="#accordion">
                            <div className="card-body">
                            {t("faqs-fourth-answer")}
                            </div>
                        </div>
                    </div> */}
                    <div className="card">
                        <div className="card-header">
                            <a className="card-link" data-toggle="collapse" href="#collapseFive">
                                <span>4</span> {t("faqs-fifth")}
                            </a>
                        </div>
                        <div id="collapseFive" className="collapse" data-parent="#accordion">
                            <div className="card-body">
                               {t("faqs-fifth-answer")}
                            </div>
                        </div>
                    </div> 
                </div>
                <a className="btn" href="#newsletter">{t("faqs-button")}</a>
            </div>
        </div>
    </div>
</div>

  );
}

export default Faqs;




